import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAllByTypeDiet() {
		const params = {
			order: 'asc',
			order_by: 'name',
			offset: 0,
			limit: 500,
		}
		const { data } = await useJwt.get(API_ROUTES.type_diet.get, params)

		return data
	},
	save(id, data) {
		return useJwt.post(`${API_ROUTES.infoNutritional.save}/${id}`, data)
	},
}
